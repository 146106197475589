<template>
  <div class="container-card">
    <img src="../../../assets/dp-icon.svg" />

    <span
      class="system-name"
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >Departamento
      Pessoal</span>

    <v-btn
      color="primary"
      data-test="ticket-button"
      class="button-access"
      @click="getRouter()"
    >
      Acessar
    </v-btn>
  </div>
  <!-- <div class="container-actions">
    <div>
      <v-card-title class="title">
        DEPARTAMENTO PESSOAL 📚📝
      </v-card-title>
    </div>
    <v-card-actions class="primary pa-0 container-button">
      <v-btn
        color="primary"
        block
        dark
        large
        data-test="ticket-button"
        @click="getRouter()"
      >
        Acessar
      </v-btn>
    </v-card-actions>
  </div> -->
</template>

<script>
import { getVuetify } from '@core/utils'

export default {
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  methods: {
    async getRouter() {
      await this.$router.push({ name: 'personal-department-home' })

      this.$router.go()
    },
  },
}
</script>

<style scoped>
.container-card{
  width: 100%;
  height: 205px;
  max-width: 223px;
  border-radius: 5px;
  border: 1px solid #3b355a;
  background: radial-gradient(73.99% 62.99% at 0% 53.89%, rgba(57, 206, 47, 0.10) 0%, rgba(126, 120, 157, 0.10) 90.1%);
  filter: blur(0.5px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-access{
  width: 60%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.system-name{
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.dark-mode {
  color: #FFF;
}

.light-mode {
  color: #736c7d;
}

.container-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  background-image: url('../../../assets/images/grid-images/personalDepartment.jpg');
  background-position: center;
  background-size: cover;

  border-radius: 15px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 540px) {
  .container-actions {
    height: 200px;
  }
}
.container-button {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.title {
  color: #fff;
  font-weight: bold;
}
</style>
