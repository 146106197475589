<template>
  <div class="container-card">
    <img src="../../../assets/academiavendas-icon.svg" />

    <span
      class="system-name"
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >Academia de Vendas</span>

    <v-btn
      color="primary"
      data-test="adm-system-button"
      class="button-access"
      @click="openNewTab"
    >
      Acessar
    </v-btn>
  </div>
</template>

<script>
import { getVuetify } from '@core/utils'

export default {
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  methods: {
    openNewTab() {
      const link = 'https://academiadevendashonda.konviva.com.br/lms/#/home'

      window.open(link, '_blank')
    },
  },
}

</script>

<style scoped>
  .container-card{
  width: 100%;
  height: 205px;
  max-width: 223px;
  border-radius: 5px;
  border: 1px solid #3b355a;
  background: radial-gradient(73.99% 62.99% at 0% 53.89%, rgba(255, 46, 0, 0.10) 0%, rgba(126, 120, 157, 0.10) 90.1%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-access{
  width: 60%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.system-name{
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.dark-mode {
  color: #FFF;
}

.light-mode {
  color: #736c7d;
}
</style>
