<template>
  <div class="container-card">
    <img src="../../../assets/adm-icon.svg" />

    <span
      class="system-name"
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >Administração
      Motoca Systems</span>

    <v-btn
      color="primary"
      data-test="adm-system-button"
      class="button-access"
      @click="$router.push({ name: 'sys-adm-system' })"
    >
      Acessar
    </v-btn>
  </div>
  <!-- <div class="container-actions">
    <div>
      <v-card-title class="title">
        ADMINISTRAÇÃO DO SISTEMA 🔐🔨
      </v-card-title>
    </div>
    <v-card-actions class="primary pa-0 container-button">
      <v-btn
        color="primary"
        block
        dark
        large
        data-test="adm-system-button"
        @click="$router.push({ name: 'sys-adm-system' })"
      >
        Acessar
      </v-btn>
    </v-card-actions>
  </div> -->
</template>

<script>
import { getVuetify } from '@core/utils'

export default {
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
}
</script>

<style scoped>
  .container-card{
  width: 100%;
  height: 205px;
  max-width: 223px;
  border-radius: 5px;
  border: 1px solid #3b355a;
  background: radial-gradient(73.99% 62.99% at 0% 53.89%, rgba(250, 255, 0, 0.10) 0%, rgba(126, 120, 157, 0.10) 90.1%);
  filter: blur(0.5px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-access{
  width: 60%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.system-name{
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.dark-mode {
  color: #FFF;
}

.light-mode {
  color: #736c7d;
}

/* .container-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  border-radius: 15px;

  background-image: url('../../../assets/images/grid-images/honda-02.jpg');
  background-position: center;
  background-size: cover;
}

@media (max-width: 540px) {
  .container-actions {
    height: 200px;
  }
}

.container-button {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.title {
  color: #fff;
  font-weight: bold;
} */
</style>
