<template>
  <div class="container-card">
    <v-snackbar
      v-model="isRoundedSnackbarVisible"
      top
      :light="$vuetify.theme.dark"
    >
      Algo deu errado ao acessar a aplicação
    </v-snackbar>
    <img src="../../../assets/smart-query.svg" />

    <span
      class="system-name"
      :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
    >
      Smart Query
    </span>

    <v-btn
      color="primary"
      data-test="adm-system-button"
      class="button-access"
      @click="getRouter"
    >
      <span v-if="!isLoading">Acessar</span>
      <v-progress-circular
        v-else
        color="white"
        indeterminate
      >
      </v-progress-circular>
    </v-btn>
  </div>
</template>

<script>

// import axiosIns from '@/plugins/axios'
import { getVuetify } from '@core/utils'
import axios from 'axios'
import localStorageSlim from 'localstorage-slim'

export default {
  data() {
    return {
      isLoading: false,
      isRoundedSnackbarVisible: false,

    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  methods: {
    async getRouter() {
      this.isLoading = true

      try {
        const response = await axios.post('https://wsnv.novavidati.com.br/wslocalizador.asmx/GerarTokenJson', {
          credencial: {
            usuario: process.env.VUE_APP_USER,
            senha: process.env.VUE_APP_PASS,
            cliente: process.env.VUE_APP_CLIENT,
          },
        })

        localStorageSlim.set('tokenNVTI', response.data.d, { encrypt: true })

        await this.$router.push({ name: 'explorer-home' })

        this.$router.go()
      } catch (error) {
        console.log(error)

        this.isRoundedSnackbarVisible = true
      } finally {
        this.isLoading = false
      }
    },
  },
}

</script>

<style scoped>
  .container-card{
  width: 100%;
  height: 205px;
  max-width: 223px;
  border-radius: 5px;
  border: 1px solid #3b355a;
  background: radial-gradient(73.99% 62.99% at 0% 53.89%, rgba(251, 29, 95, 0.10) 0%, rgba(126, 120, 157, 0.10) 90.1%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-access{
  width: 60%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.system-name{
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
}

.dark-mode {
  color: #FFF;
}

.light-mode {
  color: #736c7d;
}
</style>
